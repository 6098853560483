
const SELECT_LANGUAGE = "SELECT-LANGUAGE";
const SET_LANGUAGES = "SET-LANGUAGES";
const IP_COUNTRY_LANG = "IP-COUNTRY-LANG";


let initialState = {
    "language-list": [],
    "languageSelect": 'ru',
    "ipCountry": false
};

const languageReducer = (state = initialState, action) => {

    switch (action.type) {
        case SELECT_LANGUAGE:
            return {
                ...state,
                ["language-list"]: state["language-list"].map( (items) => {
                    if (action.languageCode === items.node.languageID) {
                        state.languageSelect = items.node.languageID;
                    }
                    return {...items}
                }),
            };
        case SET_LANGUAGES:

            const languageSelect = () => {
                if (action.location === 'panda-dv.ru' || action.location === 'new.panda-dv.ru') {
                    return 'ru';
                }
                else if (action.location === 'panda-dv.com' || action.location === 'new.panda-dv.com') {
                    return 'en';
                }
                else if (action.location === 'panda-dv.cn' || action.location === 'new.panda-dv.cn') {
                    return 'cn';
                }
                else {
                    return 'ru';
                }
            };

            return {
                ...state,
                ["language-list"]: action.languages,
                "languageSelect": languageSelect()
            };
        case IP_COUNTRY_LANG:
            if (action.country === 'RU' || action.location === 'panda-dv.ru') {
                return {
                    ...state,
                    ["languageSelect"]: 'ru',
                    "ipCountry": true
                };
            }
            else if (action.country !== 'RU' && action.country !== 'CN' || action.location === 'panda-dv.net') {
                return {
                    ...state,
                    ["languageSelect"]: 'en',
                    "ipCountry": true
                };
            }
            else if (action.country === 'CN') {
                return {
                    ...state,
                    ["languageSelect"]: 'cn',
                    "ipCountry": true
                };
            }
            else {
                return {
                    ...state,
                    ["languageSelect"]: 'ru',
                    "ipCountry": true
                };
            }

        default:
            return state;
    };

};


export const selectLanguage = (code) => ({type: SELECT_LANGUAGE, languageCode: code,});
export const setLanguages = (languages, location) => ({type: SET_LANGUAGES, languages: languages, location: location});
export const ipCountryLang = (country, location) => ({type: IP_COUNTRY_LANG, country: country, location: location});

export default languageReducer;
