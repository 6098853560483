import {filterPortfolio} from "../../api/filterPortfolio";

const SELECT_FILTER = "SELECT-FILTER";
const SET_PORTFOLIO = "SET-PORTFOLIO";
const FILTER_PORTFOLIO = "FILTER-PORTFOLIO";
const SET_PORTFOLIO_CATEGORY = "SET-PORTFOLIO-CATEGORY";


let initialState = {
    "portfolio-list": [],
    "portfolio-list-filter": [],
    "category-list": [],
    "categoryActive": false,
    "languageSelect": 'ru'
};

const languageReducer = (state = initialState, action) => {

    switch (action.type) {
        case SELECT_FILTER:
            return {
                ...state,
                ["language-list"]: state["category-list"].map( (items) => {
                    if (action.filterId === items.id) {
                        state.categoryActive = items.id;
                    }
                    else if (action.filterId === false){
                        state.categoryActive = false;
                    }
                    return {...items}
                }),
            };
        case SET_PORTFOLIO:
            return {
                ...state,
                ["portfolio-list"]: action.elements,
                ["portfolio-list-filter"]: action.elements,
            };
        case FILTER_PORTFOLIO:
            let portfolioFilters= [];

            state["portfolio-list"].map( item => {

                action.elements.map( filterId => {

                    if (item !== undefined && item.node.contentful_id === filterId) {
                        return portfolioFilters.push(item);
                    }
                })
            });
            return {
                ...state,
                ["portfolio-list-filter"]: portfolioFilters
            };
        case SET_PORTFOLIO_CATEGORY:
            return {
                ...state,
                ["category-list"]: action.elements
            };
        default:
            return state;
    };

};


const selectFilter = (filterId) => ({type: SELECT_FILTER, filterId: filterId});
export const setPortfolio = (elements) => ({type: SET_PORTFOLIO, elements: elements});
const filterItems = (elements) => ({type: FILTER_PORTFOLIO, elements: elements});
export const setPortfolioCategory = (elements) => ({type: SET_PORTFOLIO_CATEGORY, elements: elements});

export const getFilters = (categoryId, selectIdCategory) => (dispatch) => {
    return filterPortfolio(categoryId)
            .then((response) => {
                const elementsSort =
                    response.items.map( item => {
                        return item.sys.id;
                    });

                dispatch(filterItems(elementsSort));
                dispatch(selectFilter(selectIdCategory))

            })
            .catch(console.error)
};

export default languageReducer;