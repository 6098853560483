

let initialState = {
    "form-feedback": {
        "formLabelName_ru": 'Введите ваше имя:',
        "formLabelName_en": 'Enter your name:',
        "formLabelName_cn": '輸入你的名字：',
        "formPlaceholderName_ru": 'Как к вам обращаться?',
        "formPlaceholderName_en": 'What is your name?',
        "formPlaceholderName_cn": '你叫什麼名字',

        "formLabelEmail_ru": 'Введите ваш email:',
        "formLabelEmail_en": 'Enter your email:',
        "formLabelEmail_cn": '輸入你的郵箱:',
        "formPlaceholderEmail_ru": 'Ваш email для связи',
        "formPlaceholderEmail_en": 'Your email for communication',
        "formPlaceholderEmail_cn": '您用於通訊的電子郵件',

        "formLabelPhone_ru": 'Введите ваш номер телефона:',
        "formLabelPhone_en": 'Enter your phone number:',
        "formLabelPhone_cn": '輸入你的郵箱:',
        "formPlaceholderPhone_ru": 'Ваш номер телефона для связи',
        "formPlaceholderPhone_en": 'Your number phone for communication',
        "formPlaceholderPhone_cn": '您用於通訊的電子郵件',

        "formLabelText_ru": 'Ваше сообщение:',
        "formLabelText_en": 'Your message:',
        "formLabelText_cn": '您的留言：',
        "formPlaceholderText_ru": 'Введите сообщение',
        "formPlaceholderText_en": 'Enter your message',
        "formPlaceholderText_cn": '輸入訊息',


        "button_ru": 'Отправить сообщение',
        "button_en": 'Send a message',
        "button_cn": '傳送訊息',
    },
    "consent_ru": 'Нажимая на кнопку \'Отправить сообщение’, вы даете согласие на обработку своих персональных данных',
    "consent_en": 'By clicking on the button \'Send message ’, you consent to the processing of your personal data',
    "consent_cn": '點擊“發送消息”按鈕，即表示您同意處理您的個人數據',

    "errorLabel_ru": 'Обязательное поле',
    "errorLabel_en": 'Required field',
    "errorLabel_cn": 'Required field',

    "errorLabelName_ru": 'Поле содержит цифры',
    "errorLabelName_en": 'The field contains numbers',
    "errorLabelName_cn": 'The field contains numbers',

    "errorLabelEmail_ru": 'Неверный адрес электронной почты',
    "errorLabelEmail_en": 'Invalid email address',
    "errorLabelEmail_cn": 'Invalid email address',

    "successSubtitle_ru": 'Спасибо',
    "successSubtitle_en": 'Thanks',
    "successSubtitle_cn": '謝謝你',

    "successMessage_ru": 'Мы свяжемся с вами в ближайшее время',
    "successMessage_en": 'We will contact you shortly',
    "successMessage_cn": '我們會盡快與您聯繫',

    "successButtonClose_ru": 'Закрыть',
    "successButtonClose_en": 'Close',
    "successButtonClose_cn": '關門',
};

const formReducer = (state = initialState) => {

    return state;

};



export default formReducer;
