import {combineReducers, createStore, applyMiddleware} from "redux";
import thunk from 'redux-thunk';


import languageReducer from "./reducers/languageReducer";
import formReducer from "./reducers/formReducer";
import menuHamburger from "./reducers/menuHamburger";
import portfolioReducer from "./reducers/portfolioReducer";



let reducers = combineReducers({
    languageSelect: languageReducer,
    form: formReducer,
    menuHamburger: menuHamburger,
    portfolio: portfolioReducer,

});

let store = createStore(reducers, applyMiddleware(thunk));

export default store;
