/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { Provider } from 'react-redux';


import store from "./src/redux/redux-store";
// You can delete this file if you're not using it
/*export { default as wrapRootElement } from './src/redux/reduxWrapper';*/

export const wrapRootElement = ({ element }) => (
    <Provider store={store}>
            {element}
    </Provider>
);
