import React from "react";
import * as contentful from 'contentful';


const client = contentful.createClient({
    space: process.env.spaceId,
    accessToken: process.env.accessToken,
});


export const filterPortfolio = (categoryId) => {
    return (
    client.getEntries({
        'fields.category.sys.id': categoryId ? categoryId : '',
        'content_type': 'portfolio',
        select: 'sys.id'
    })
    );

};


